export const getArrayPiece = (array, page, pageLength) => {
  const start = (page - 1) * pageLength;
  const end =
    start + pageLength > array.length ? array.length : start + pageLength;
  return array.slice(start, end);
};

const localization = [
  {
    title: "Czech",
    iso: "cz"
  },
  {
    title: "English",
    iso: "en"
  },
  {
    title: "Hungarian",
    iso: "hu"
  },
  {
    title: "Polish",
    iso: "pl"
  },
  {
    title: "Ukranian",
    iso: "uk"
  },
  {
    title: "Slovak",
    iso: "sk"
  }
];

export const getLangs = (route = { path: "", query: {} }) => {
  const { path, query } = route;
  const isZeroPhase =
    (query.phase && query.phase === "0") || ("/new" && !window.location.search);
  const isHomepage = path === "/";

  return isZeroPhase || isHomepage
    ? localization
    : [
        {
          title: "english",
          iso: "en"
        }
      ];
};
